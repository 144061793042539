import { LayoutProps } from "./_model";
export default function Layout(props: LayoutProps) {
  return (
   
      <div className="flex flex-col w-full h-full bg-white items-center justify-center overflow-hidden <md:(w-full h-full)">
        <div className="bg-[#0D2937] w-full h-15 px-3 py-2"> 
        <img src="/logo.png" className="w-15" alt="" />
        </div>
        {props.children}
      </div>
  );
}
